import Button from '../../presentational/Button';
import EditLocationButton from './EditLocationButton';
import EditTenantButton from './EditTenantButton';
import EditToolButton from './EditToolButton';
import EditUserButton from './EditUserButton';
import GoToLocationsButton from './GoToLocationsButton';
import GoToTenantsButton from './GoToTenantsButton';
import GoToToolsButton from './GoToToolsButton';
import GoToUsersButton from './GoToUsersButton';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import SaveLocationButton from './SaveLocationButton';
import SaveTenantButton from './SaveTenantButton';
import SaveToolButton from './SaveToolButton';
import SaveUserButton from './SaveUserButton';
import UploadTenantLogoButton from './UploadTenantLogoButton';
import UploadToolImageButton from './UploadToolImageButton';
import UploadUserPhotoButton from './UploadUserPhotoButton';
import GoToLabelsButton from './GoToLabelsButton';
import GoToRolesButton from './GoToRolesButton';
import SaveLabelButton from './SaveLabelButton';
import GoToDropdownsButton from './GoToDropdownsButton';
import SaveDropdownButton from './SaveDropdownButton';
import EditLabelButton from './EditLabelButton';
import GoToWorkPackagesButton from './GoToWorkPackages';
import SaveWorkPackageButton from './SaveWorkPackageButton';
import EditWorkPackageButton from './EditWorkPackageButton';
import SaveWorkPackageToolButton from './SaveWorkPackageToolButton';
import EditWorkPackageToolButton from './EditWorkPackageToolButton';
import GetToolSampleButton from './GetToolSampleButton';
import GoToWorkPackageToolsButton from './GoToWorkPackageTools';
import DuplicateWorkPackageButton from './DuplicateWorkPackageButton';
import SaveDuplicateWorkPackageButton from './SaveDuplicateWorkPackageButton';
import DeleteWorkPackageButton from './DeleteWorkPackageButton';
import EditDropdownButton from './EditDropdownButton';
import GoToInspectionButton from './GoToInspectionButton';
import SaveInspectionTypeButton from './SaveInspectionTypeButton';
import EditInspectionTypeButton from './EditInspectionTypeButton';
import GoToInspectionItemButton from './GoToInspectionItemButton';
import SaveInspectionItemButton from './SaveInspectionItemButton';
import EditInspectionItemButton from './EditInspectionItemButton';
import GoToToolStatusButton from './GoToToolStatusButton';
import EditToolStatusButton from './EditToolStatusButton';
import SaveToolStatusButton from './SaveToolStatusButton';
import ReserveWorkPackageButton from './ReserveWorkPackageButton';
import GoToStorageLocationsButton from './GoToStorageLocationsButton';
import GoToBrokenToolsButton from './GoToBrokenToolsButton';
import DeleteInspectionItemButton from './DeleteInspectionItemButton';
import RemoveWorkPackageToolButton from './RemoveWorkPackageToolButton';
import ReportBrokenToolButton from './ReportBrokenToolButton';
import EditBrokenToolButton from './EditBrokenToolButton';
import CancelBrokenToolButton from './CancelBrokenToolsButton';
import SaveBrokenToolButton from './SaveBrokenToolButton';
import UploadBrokenToolImageButton from './UploadBrokenToolImageButton';
import SaveStorageLocationButton from './SaveStorageLocationButton';
import EditStorageLocationButton from './EditStorageLocationButton';
import GoToToolCategoryButton from './GoToToolCategoryButton';
import SaveToolCategoryButton from './SaveToolCategoryButton';
import EditToolCategoryButton from './EditToolCategoryButton';
import GoToOEMStatusButton from './GoToOEMStatusButton';
import SaveOEMStatusButton from './SaveOEMStatusButton';
import EditOEMStatusButton from './EditOEMStatusButton';
import GoToMessageCenterButton from './GoToMessageCenterButton';
import RotateToolImageButton from './RotateImageButton';
import GoToToolTransferButton from './GoToToolTransfersButton';
import GoToReportingButton from './GoToReportingButton';
import GoToReadinessScoreButton from './GoToReadinessScoreButton';
import GoToAddReadinessScoreButton from './GoToAddReadinessScoreButton';

Button.UploadTenantLogo = UploadTenantLogoButton;
Button.SaveTenant = SaveTenantButton;
Button.EditTenant = EditTenantButton;
Button.GoToTenants = GoToTenantsButton;
Button.GoToLabels = GoToLabelsButton;
Button.SaveLabel = SaveLabelButton;
Button.EditLabel = EditLabelButton;
Button.SaveUser = SaveUserButton;
Button.UploadUserPhoto = UploadUserPhotoButton;
Button.EditUser = EditUserButton;
Button.GoToUsers = GoToUsersButton;
Button.SaveLocation = SaveLocationButton;
Button.EditLocation = EditLocationButton;
Button.GoToLocations = GoToLocationsButton;
Button.SaveTool = SaveToolButton;
Button.GoToTools = GoToToolsButton;
Button.UploadToolImage = UploadToolImageButton;
Button.RotateImage = RotateToolImageButton;
Button.EditTool = EditToolButton;
Button.GoToRoles = GoToRolesButton;
Button.GoToDropdowns = GoToDropdownsButton;
Button.SaveDropdown = SaveDropdownButton;
Button.EditDropdown = EditDropdownButton;
Button.GoToWorkPackages = GoToWorkPackagesButton;
Button.GoToWorkPackageTools = GoToWorkPackageToolsButton;
Button.SaveWorkPackage = SaveWorkPackageButton;
Button.SaveWorkPackageTool = SaveWorkPackageToolButton;
Button.EditWorkPackage = EditWorkPackageButton;
Button.DuplicateWorkPackage = DuplicateWorkPackageButton;
Button.SaveDuplicateWorkPackage = SaveDuplicateWorkPackageButton;
Button.EditWorkPackageTool = EditWorkPackageToolButton;
Button.DeleteWorkPackage = DeleteWorkPackageButton;
Button.GetToolSample = GetToolSampleButton;
Button.GoToInspection = GoToInspectionButton;
Button.GoToInspectionItem = GoToInspectionItemButton;
Button.SaveInspectionItem = SaveInspectionItemButton;
Button.SaveInspectionType = SaveInspectionTypeButton;
Button.EditInspectionType = EditInspectionTypeButton;
Button.EditInspectionItem = EditInspectionItemButton;

Button.GoToToolStatus = GoToToolStatusButton;

Button.EditToolStatus = EditToolStatusButton;
Button.SaveToolStatus = SaveToolStatusButton;
Button.ReserveWorkPackage = ReserveWorkPackageButton;
Button.Login = LoginButton;
Button.Logout = LogoutButton;

Button.GoToStorageLocations = GoToStorageLocationsButton;
Button.SaveStorageLocation = SaveStorageLocationButton;
Button.EditStorageLocation = EditStorageLocationButton;

Button.ReportBrokenTool = ReportBrokenToolButton;
Button.GoToBrokenTools = GoToBrokenToolsButton;

Button.DeleteInspectionItem = DeleteInspectionItemButton;
Button.RemoveWorkPackageTool = RemoveWorkPackageToolButton;

Button.EditBrokenTool = EditBrokenToolButton;
Button.CancelBrokenTool = CancelBrokenToolButton;
Button.SaveBrokenTool = SaveBrokenToolButton;
Button.UploadBrokenTool = UploadBrokenToolImageButton;

Button.GoToToolCategory = GoToToolCategoryButton;

Button.SaveToolCategory = SaveToolCategoryButton;
Button.EditToolCategory = EditToolCategoryButton;
Button.GoToOEMStatus = GoToOEMStatusButton;

Button.SaveOEMStatus = SaveOEMStatusButton;
Button.EditOEMStatus = EditOEMStatusButton;

Button.GoToMessages = GoToMessageCenterButton;
Button.GoToTransfers = GoToToolTransferButton;
Button.GoToReporting = GoToReportingButton;
Button.GoToReadiness = GoToReadinessScoreButton;
Button.GoToAddReadiness = GoToAddReadinessScoreButton;
export default Button;
