import SearchBar from '../../presentational/SearchBar';
import DropdownSearchBar from './DropdownSearchBar';
import InspectionItemsSearchBar from './InspectionItemsSearchBar';
import InspectionSearchBar from './InspectionSearchBar';
import LabelsSearchBar from './LabelsSearchBar';
import LocationSearchBar from './LocationSearchBar';
import MessagesSearchBar from './MessagesSearchBar';
import OEMStatusSearchBar from './OEMStatusSearchBar';
import RolesSearchBar from './RolesSearchBar';
import StorageLocationSearchBar from './StorageLocationSearchBar';
import TenantsSearchBar from './TenantsSearchBar';
import ToolCategorySearchBar from './ToolCategorySearchBar';
import ToolSearchBar from './ToolSearchBar';
import ToolStatusSearchBar from './ToolStatusSearchBar';
import TransferSearchBar from './TransferSearchBar';
import UsersSearchBar from './UsersSearchBar';
import WorkPackageSearchBar from './WorkPackageSearchBar';
import WorkPackageToolsSearchBar from './WorkPackageToolsSearchBar';

SearchBar.Tenant = TenantsSearchBar;
SearchBar.Users = UsersSearchBar;
SearchBar.Locations = LocationSearchBar;
SearchBar.StorageLocations = StorageLocationSearchBar;
SearchBar.Tools = ToolSearchBar;
SearchBar.Labels = LabelsSearchBar;
SearchBar.Roles = RolesSearchBar;
SearchBar.WorkPackage = WorkPackageSearchBar;
SearchBar.WorkPackageTools = WorkPackageToolsSearchBar;
SearchBar.Dropdowns = DropdownSearchBar;
SearchBar.Inspection = InspectionSearchBar;
SearchBar.InspectionItems = InspectionItemsSearchBar;

SearchBar.ToolStatus = ToolStatusSearchBar;
SearchBar.ToolCategory = ToolCategorySearchBar;
SearchBar.Messages = MessagesSearchBar;
SearchBar.Transfers = TransferSearchBar;

SearchBar.OEMStatus = OEMStatusSearchBar;

export default SearchBar;
