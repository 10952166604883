import { View as Form } from 'react-native';
import EditTenantForm from './EditTenantForm';
import AddTenantForm from './AddTenantForm';
import EditLabelForm from './EditLabelForm';
import AddDuplicateWorkPackageForm from './AddDuplicateWorkPackageForm';
import UserForm from './UserForm';
import ToolStatusForm from './ToolStatusForm';
import LocationForm from './LocationForm';
import DropdownForm from './DropdownForm';
import WorkPackageForm from './WorkPackageForm';
import AddWorkPackageToolForm from './AddWorkPackageToolForm';
import InspectionTypeForm from './InspectionTypeForm';
import InspectionItemForm from './InspectionItemForm';
import StorageLocationForm from './StorageLocationForm';
import BrokenToolForm from './BrokenToolForm';
import ToolCategoryForm from './ToolCategoryForm';
import EditWorkPackageToolForm from './EditWorkPackageToolForm';
import OEMStatusForm from './OEMStatusForm';
import AddReadinessScorecardForm from './AddReadinessScorecardForm';

Form.EditTenant = EditTenantForm;
Form.AddTenant = AddTenantForm;
Form.EditLabel = EditLabelForm;
Form.AddDuplicateWorkPackage = AddDuplicateWorkPackageForm;

Form.User = UserForm;
Form.ToolStatus = ToolStatusForm;
Form.Location = LocationForm;
Form.StorageLocation = StorageLocationForm;
Form.Dropdown = DropdownForm;
Form.WorkPackage = WorkPackageForm;
Form.WorkPackageTool = AddWorkPackageToolForm;
Form.EditWorkPackageTool = EditWorkPackageToolForm;
Form.InspectionType = InspectionTypeForm;
Form.InspectionItem = InspectionItemForm;
Form.BrokenTool = BrokenToolForm;
Form.ToolCategory = ToolCategoryForm;
Form.OEMStatus = OEMStatusForm;
Form.AddReadinessScore = AddReadinessScorecardForm;

export default Form;
