import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import { Platform, ScrollView } from 'react-native';
import List from '../List';

export default function ReportModal({ onPress, ...props }) {
  const {
    tools: { reportType, showReportsModal, reportListData },
  } = useStoreState((state) => state);

  const { setReportsModalAction, setReportListDataAction } = useStoreActions(
    (actions) => actions
  );
  return (
    <CustomModal
      onClose={() => {
        setReportsModalAction(false);
        setReportListDataAction([]);
      }}
      isVisible={showReportsModal}
      title='Report'
      style={{
        height: Platform.OS == 'web' ? '50vh' : '50%',
        width: Platform.OS == 'web' ? '70vh' : '70%',
      }}
    >
      <ScrollView
        style={{
          height: Platform.OS == 'web' ? '40vh' : '40%',
        }}
      >
        <List.ReportList
          onPress={onPress}
          data={reportListData}
          includeDate={reportType !== 'inspections'}
          dateField={
            reportType === 'inspections'
              ? ''
              : reportType === 'calibrations'
              ? 'nextCalibrationTestDueDate'
              : 'loadTestDueDate'
          }
        ></List.ReportList>
      </ScrollView>
    </CustomModal>
  );
}
