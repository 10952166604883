import { useState } from 'react';
import { Card, Tab } from '@rneui/themed';
import { TouchableOpacity, View } from 'react-native';

import BarChartGraph from '../../presentational/BarChart';
import Icon from '../../presentational/Icon';
import { Entypo } from '@expo/vector-icons';
import ComboChartGraph from '../../presentational/ComboChart';

function ReadinessScorecardCard({
  title,
  onPress,
  dataset,
  height,
  loading = true,
  fillColor,
  addAction,
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <Card
      containerStyle={{
        backgroundColor: 'white',
        margin: 0,
        width: '100%',
        padding: '24px',
        boxShadow: '0px 0px 10px grey',
        minHeight: '300px',
      }}
      wrapperStyle={{ flex: 1 }}
    >
      <Card.Title>
        {title}
        <TouchableOpacity style={{ paddingLeft: '1rem' }} onPress={addAction}>
          <Icon Component={Entypo} name='plus' size={20} />
        </TouchableOpacity>
      </Card.Title>
      <Tab value={selectedIndex} onChange={(e) => setSelectedIndex(e)}>
        <Tab.Item title='Current' titleStyle={{ fontSize: 12 }} />
        <Tab.Item title='History' titleStyle={{ fontSize: 12 }} />
      </Tab>
      <View style={{ flex: 1 }}>
        {selectedIndex === 0 && !loading && (
          <BarChartGraph
            onPress={onPress}
            dataset={dataset.current?.datasets || []}
            height={height}
            fillColor={fillColor}
            yAxis={{ title: 'Score', ticks: [0, 1, 2, 3] }}
          ></BarChartGraph>
        )}
        {selectedIndex === 1 && !loading && (
          <ComboChartGraph
            data={dataset.previous?.dataset || []}
            height={height}
            fillColor={fillColor}
            yAxis={{ title: 'Score', ticks: [0, 1, 2, 3] }}
          ></ComboChartGraph>
        )}
      </View>
    </Card>
  );
}

export default ReadinessScorecardCard;
