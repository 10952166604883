import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect, useState } from 'react';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Row from '../presentational/Row';
import Col from '../presentational/Col';
import Button from '../presentational/Button';
import Text from '../presentational/Text';
import { Badge, Card, Icon, ListItem } from '@rneui/themed';
import { ActivityIndicator, TouchableOpacity, View } from 'react-native';
import CustomModal from '../presentational/Modal';
import Input from '../presentational/Input';

const Container = styled.View``;

const SRow = styled(Row)``;
const SCol = styled(Col)`
  padding: 0.5rem;
`;

function ReportingPage({ navigation, ...props }) {
  const [serialNumber, setSerialNumber] = useState('');
  const isFocused = useIsFocused();

  const {
    getReportsThunk,
    setReportsModalAction,
    setReportTypeAction,
    getToolsByEngineThunk,
    expandToolsByEngineAction,
    expandToolsByStatusAction,
    expandToolsByOEMStatusAction,
    setToolsInputAction,
    setReportListDataAction,
    resetToolsByEngineAction,
  } = useStoreActions((actions) => actions);

  const {
    searchResults,
    toolsByEngine,
    toolsByEngineMap,
    reports,
    toolsByStatusMap,
    toolsByOEMStatusMap,
    reportsLoading,
  } = useStoreState((store) => store.tools);
  useEffect(() => {
    if (isFocused) {
      getReportsThunk();
    }
    return () => {
      resetToolsByEngineAction();
    };
  }, [isFocused]);

  const toolSelected = (tool) => {
    const foundTool = searchResults.find(
      (item) =>
        item.partNumber === tool.partNumber &&
        item.serialNumber === tool.serialNumber
    );
    setToolsInputAction(foundTool);
    setReportsModalAction(false);
    navigation.navigate('Edit Tool', { id: foundTool.id });
  };

  return (
    <Page.Protected {...props}>
      <CustomModal.Reports onPress={toolSelected}></CustomModal.Reports>
      <Container>
        <View style={{ flex: 1, height: '100%', padding: '1rem' }}>
          <SRow>
            <SCol xs={12} sm={4}>
              <Card
                containerStyle={{
                  backgroundColor: 'white',
                  margin: 0,
                  width: '100%',
                  padding: '24px',
                  boxShadow: '0px 0px 10px grey',
                  minHeight: '300px',
                }}
                wrapperStyle={{ flex: 1 }}
              >
                <Card.Title>Tool Status</Card.Title>
                {!reportsLoading && (
                  <View style={{ flex: 1 }}>
                    <SRow style={{ flex: 1 }}>
                      <SCol xs={12} sm={12}>
                        {Object.entries(toolsByStatusMap)?.map(
                          ([key, value]) => {
                            return (
                              <ListItem.Accordion
                                key={key}
                                content={
                                  <ListItem.Content>
                                    <ListItem.Title>
                                      <Text
                                        style={{
                                          fontSize: 18,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {value.name || 'No Facility'} (
                                        {value.count})
                                      </Text>
                                    </ListItem.Title>
                                  </ListItem.Content>
                                }
                                icon={
                                  <Icon name={'chevron-down'} type='entypo' />
                                }
                                isExpanded={toolsByStatusMap[key].expanded}
                                onPress={() => {
                                  expandToolsByStatusAction({
                                    key: key,
                                    isOpen: !toolsByStatusMap[key].expanded,
                                  });
                                }}
                              >
                                {Object.entries(
                                  toolsByStatusMap[key].locationStatusMap
                                )?.map(([key, value], index) => {
                                  return (
                                    <ListItem
                                      key={index}
                                      bottomDivider
                                      onPress={() => {
                                        setReportTypeAction('inspections');
                                        setReportListDataAction(value.tools);
                                        setReportsModalAction(true);
                                      }}
                                    >
                                      <Badge status={value.type} />
                                      <ListItem.Content>
                                        <ListItem.Title>{key}</ListItem.Title>
                                      </ListItem.Content>
                                      <Badge
                                        status={value.type}
                                        value={value.tools?.length}
                                      />
                                    </ListItem>
                                  );
                                })}
                              </ListItem.Accordion>
                            );
                          }
                        )}
                      </SCol>
                    </SRow>
                  </View>
                )}
                {reportsLoading && (
                  <View
                    pointerEvents='none'
                    style={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#F5FCFF88',
                      zIndex: 1000,
                    }}
                  >
                    <ActivityIndicator size='large' />
                  </View>
                )}
              </Card>
            </SCol>
            <SCol xs={12} sm={4}>
              <Card
                containerStyle={{
                  backgroundColor: 'white',
                  margin: 0,
                  width: '100%',
                  padding: '24px',
                  boxShadow: '0px 0px 10px grey',
                  minHeight: '300px',
                }}
                wrapperStyle={{ flex: 1 }}
              >
                <Card.Title>OEM Status</Card.Title>
                {!reportsLoading && (
                  <View style={{ flex: 1 }}>
                    <SRow style={{ flex: 1 }}>
                      <SCol xs={12} sm={12}>
                        {Object.entries(toolsByOEMStatusMap)?.map(
                          ([key, value]) => {
                            return (
                              <ListItem.Accordion
                                key={key}
                                content={
                                  <ListItem.Content>
                                    <ListItem.Title>
                                      <Text
                                        style={{
                                          fontSize: 18,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {value.name || 'No Facility'} (
                                        {value.count})
                                      </Text>
                                    </ListItem.Title>
                                  </ListItem.Content>
                                }
                                icon={
                                  <Icon name={'chevron-down'} type='entypo' />
                                }
                                isExpanded={toolsByOEMStatusMap[key].expanded}
                                onPress={() => {
                                  expandToolsByOEMStatusAction({
                                    key: key,
                                    isOpen: !toolsByOEMStatusMap[key].expanded,
                                  });
                                }}
                              >
                                {Object.entries(
                                  toolsByOEMStatusMap[key].locationStatusMap
                                )?.map(([key, value], index) => {
                                  return (
                                    <ListItem
                                      key={index}
                                      bottomDivider
                                      onPress={() => {
                                        setReportTypeAction('inspections');
                                        setReportListDataAction(value.tools);
                                        setReportsModalAction(true);
                                      }}
                                    >
                                      <Badge status={value.type} />
                                      <ListItem.Content>
                                        <ListItem.Title>{key}</ListItem.Title>
                                      </ListItem.Content>
                                      <Badge
                                        status={value.type}
                                        value={value.tools?.length}
                                      />
                                    </ListItem>
                                  );
                                })}
                              </ListItem.Accordion>
                            );
                          }
                        )}
                      </SCol>
                    </SRow>
                  </View>
                )}
                {reportsLoading && (
                  <View
                    pointerEvents='none'
                    style={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#F5FCFF88',
                      zIndex: 1000,
                    }}
                  >
                    <ActivityIndicator size='large' />
                  </View>
                )}
              </Card>
            </SCol>
            <SCol xs={12} sm={4}>
              <Card
                containerStyle={{
                  backgroundColor: 'white',
                  margin: 0,
                  width: '100%',
                  padding: '24px',
                  boxShadow: '0px 0px 10px grey',
                  minHeight: '300px',
                }}
                wrapperStyle={{ flex: 1 }}
              >
                <Card.Title>Inspections</Card.Title>
                {!reportsLoading && (
                  <View style={{ flex: 1 }}>
                    <SRow style={{ flex: 1, alignItems: 'center' }}>
                      <SCol xs={12} sm={12}>
                        <TouchableOpacity
                          style={{ alignItems: 'center' }}
                          onPress={() => {
                            setReportTypeAction('inspections');
                            setReportListDataAction(reports['inspections']);
                            setReportsModalAction(true);
                          }}
                        >
                          <div>
                            <Text
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                color: '#FAAA20',
                              }}
                            >
                              {reports['inspections']?.length}
                            </Text>
                          </div>
                          <div>
                            <Text style={{ fontSize: 17, color: 'gray' }}>
                              Due Soon
                            </Text>
                          </div>
                        </TouchableOpacity>
                      </SCol>
                    </SRow>
                  </View>
                )}
                {reportsLoading && (
                  <View
                    pointerEvents='none'
                    style={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#F5FCFF88',
                      zIndex: 1000,
                    }}
                  >
                    <ActivityIndicator size='large' />
                  </View>
                )}
              </Card>
            </SCol>
            <SCol xs={12} sm={4}>
              <Card
                containerStyle={{
                  backgroundColor: 'white',
                  margin: 0,
                  width: '100%',
                  padding: '24px',
                  boxShadow: '0px 0px 10px grey',
                  minHeight: '300px',
                }}
                wrapperStyle={{ flex: 1 }}
              >
                <Card.Title>Load Tests</Card.Title>
                {!reportsLoading && (
                  <View style={{ flex: 1 }}>
                    <SRow style={{ flex: 1, alignItems: 'center' }}>
                      <SCol xs={12} sm={6}>
                        <TouchableOpacity
                          style={{ alignItems: 'center' }}
                          onPress={() => {
                            setReportTypeAction('loadTests');
                            setReportListDataAction(
                              reports.loadTests?.filter(
                                (x) => new Date(x.loadTestDueDate) < new Date()
                              )
                            );
                            setReportsModalAction(true);
                          }}
                        >
                          <div>
                            <Text
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                color: '#ab2328',
                              }}
                            >
                              {
                                reports.loadTests?.filter(
                                  (x) =>
                                    new Date(x.loadTestDueDate) < new Date()
                                ).length
                              }
                            </Text>
                          </div>
                          <div>
                            <Text style={{ fontSize: 17, color: 'gray' }}>
                              Overdue
                            </Text>
                          </div>
                        </TouchableOpacity>
                      </SCol>
                      <SCol xs={12} sm={6}>
                        <TouchableOpacity
                          style={{ alignItems: 'center' }}
                          onPress={() => {
                            setReportTypeAction('loadTests');
                            setReportListDataAction(
                              reports.loadTests?.filter(
                                (x) => new Date(x.loadTestDueDate) > new Date()
                              )
                            );
                            setReportsModalAction(true);
                          }}
                        >
                          <div>
                            <Text
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                color: '#FAAA20',
                              }}
                            >
                              {
                                reports.loadTests?.filter(
                                  (x) =>
                                    new Date(x.loadTestDueDate) > new Date()
                                ).length
                              }
                            </Text>
                          </div>
                          <div>
                            <Text style={{ fontSize: 17, color: 'gray' }}>
                              Due Soon
                            </Text>
                          </div>
                        </TouchableOpacity>
                      </SCol>
                    </SRow>
                  </View>
                )}
                {reportsLoading && (
                  <View
                    pointerEvents='none'
                    style={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#F5FCFF88',
                      zIndex: 1000,
                    }}
                  >
                    <ActivityIndicator size='large' />
                  </View>
                )}
              </Card>
            </SCol>
            <SCol xs={12} sm={4}>
              <Card
                containerStyle={{
                  backgroundColor: 'white',
                  margin: 0,
                  width: '100%',
                  padding: '24px',
                  boxShadow: '0px 0px 10px grey',
                  minHeight: '300px',
                }}
                wrapperStyle={{ flex: 1 }}
              >
                <Card.Title>Calibrations</Card.Title>
                {!reportsLoading && (
                  <View style={{ flex: 1 }}>
                    <SRow style={{ flex: 1, alignItems: 'center' }}>
                      <SCol xs={12} sm={6}>
                        <TouchableOpacity
                          style={{ alignItems: 'center' }}
                          onPress={() => {
                            setReportTypeAction('calibrations');
                            setReportListDataAction(
                              reports.calibrations?.filter(
                                (x) =>
                                  new Date(x.nextCalibrationTestDueDate) <
                                  new Date()
                              )
                            );
                            setReportsModalAction(true);
                          }}
                        >
                          <div>
                            <Text
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                color: '#ab2328',
                              }}
                            >
                              {
                                reports.calibrations?.filter(
                                  (x) =>
                                    new Date(x.nextCalibrationTestDueDate) <
                                    new Date()
                                ).length
                              }
                            </Text>
                          </div>
                          <div>
                            <Text style={{ fontSize: 17, color: 'gray' }}>
                              Overdue
                            </Text>
                          </div>
                        </TouchableOpacity>
                      </SCol>
                      <SCol xs={12} sm={6}>
                        <TouchableOpacity
                          style={{ alignItems: 'center' }}
                          onPress={() => {
                            setReportTypeAction('calibrations');
                            setReportListDataAction(
                              reports.calibrations?.filter(
                                (x) =>
                                  new Date(x.nextCalibrationTestDueDate) >
                                  new Date()
                              )
                            );
                            setReportsModalAction(true);
                          }}
                        >
                          <div>
                            <Text
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                color: '#FAAA20',
                              }}
                            >
                              {
                                reports.calibrations?.filter(
                                  (x) =>
                                    new Date(x.nextCalibrationTestDueDate) >
                                    new Date()
                                ).length
                              }
                            </Text>
                          </div>
                          <div>
                            <Text style={{ fontSize: 17, color: 'gray' }}>
                              Due Soon
                            </Text>
                          </div>
                        </TouchableOpacity>
                      </SCol>
                    </SRow>
                  </View>
                )}
                {reportsLoading && (
                  <View
                    pointerEvents='none'
                    style={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#F5FCFF88',
                      zIndex: 1000,
                    }}
                  >
                    <ActivityIndicator size='large' />
                  </View>
                )}
              </Card>
            </SCol>
            <SCol xs={12} sm={4}>
              <Card
                containerStyle={{
                  backgroundColor: 'white',
                  margin: 0,
                  width: '100%',
                  padding: '24px',
                  boxShadow: '0px 0px 10px grey',
                  minHeight: '300px',
                }}
                wrapperStyle={{ flex: 1 }}
              >
                <Card.Title>Engine Report</Card.Title>
                {!reportsLoading && (
                  <View style={{ flex: 1 }}>
                    <SRow>
                      <SCol xs={12} sm={8}>
                        <Input
                          placeholder='Serial Number'
                          value={serialNumber}
                          onChangeText={(value) => setSerialNumber(value)}
                        />
                      </SCol>
                      <SCol xs={12} sm={4}>
                        <Button
                          title='Generate'
                          fullWidth
                          disabled={!serialNumber}
                          onPress={() => {
                            getToolsByEngineThunk(serialNumber);
                          }}
                        />
                      </SCol>
                    </SRow>
                    <SRow>
                      <SCol xs={12} sm={12}>
                        {toolsByEngine?.map((tool) => {
                          return (
                            <ListItem.Accordion
                              key={tool.key}
                              content={
                                <ListItem.Content>
                                  <ListItem.Title>
                                    <Text
                                      style={{
                                        fontSize: 18,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      Work Order: {tool.workOrder} (
                                      {tool.usedDate})
                                    </Text>
                                  </ListItem.Title>
                                  <ListItem.Subtitle>
                                    <Text>User: {tool.user}</Text>
                                  </ListItem.Subtitle>
                                </ListItem.Content>
                              }
                              icon={
                                <Icon name={'chevron-down'} type='entypo' />
                              }
                              isExpanded={toolsByEngineMap[tool.key].expanded}
                              onPress={() => {
                                expandToolsByEngineAction({
                                  key: tool.key,
                                  isOpen: !toolsByEngineMap[tool.key].expanded,
                                });
                              }}
                            >
                              {tool.tools?.map((item, i) => {
                                return (
                                  <ListItem
                                    containerStyle={{ marginLeft: '1rem' }}
                                    key={i}
                                  >
                                    <ListItem.Content>
                                      <ListItem.Title>
                                        <TouchableOpacity
                                          onPress={() => toolSelected(item)}
                                        >
                                          <Text
                                            style={{
                                              textDecoration: 'underline',
                                            }}
                                          >
                                            {item.partNumber} (
                                            {item.serialNumber})
                                          </Text>
                                        </TouchableOpacity>
                                      </ListItem.Title>
                                      <ListItem.Subtitle>
                                        {item.description}
                                      </ListItem.Subtitle>
                                    </ListItem.Content>
                                  </ListItem>
                                );
                              })}
                            </ListItem.Accordion>
                          );
                        })}
                      </SCol>
                    </SRow>
                  </View>
                )}
                {reportsLoading && (
                  <View
                    pointerEvents='none'
                    style={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#F5FCFF88',
                      zIndex: 1000,
                    }}
                  >
                    <ActivityIndicator size='large' />
                  </View>
                )}
              </Card>
            </SCol>
          </SRow>
        </View>
      </Container>
    </Page.Protected>
  );
}

export default ReportingPage;
