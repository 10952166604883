const state = {
  messages: {
    messageMap: {},
    searchResults: [],
    searchInput: '',
    showArchived: false,
    selectedId: '',
    comments: [],
    commentMap: {},
    loading: false,
    selectedId: null,
    selectedMessage: null,
  },
};

const MessageStatus = Object.freeze({
  Unread: '1',
  Read: '2',
  Archived: '3',
});

export { state as default, MessageStatus };
