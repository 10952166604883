import { StoreProvider } from 'easy-peasy';
import AppNavigator from './src/components/navigation/AppNavigator';
import store from './src/store';
import 'react-native-gesture-handler';
import { useFonts } from 'expo-font';
import {
  Barlow_100Thin,
  Barlow_100Thin_Italic,
  Barlow_200ExtraLight,
  Barlow_200ExtraLight_Italic,
  Barlow_300Light,
  Barlow_300Light_Italic,
  Barlow_400Regular,
  Barlow_400Regular_Italic,
  Barlow_500Medium,
  Barlow_500Medium_Italic,
  Barlow_600SemiBold,
  Barlow_600SemiBold_Italic,
  Barlow_700Bold,
  Barlow_700Bold_Italic,
  Barlow_800ExtraBold,
  Barlow_800ExtraBold_Italic,
  Barlow_900Black,
  Barlow_900Black_Italic,
} from '@expo-google-fonts/barlow';
import { axiosInterceptor } from './src/utilities/api';
import { authAxios } from './src/api/auth';
import { documentAxios } from './src/api/document';
import { locationAxios } from './src/api/locations';
import { roleAxios } from './src/api/role';
import { tenantAxios } from './src/api/tenant';
import { toolsAxios } from './src/api/tool';
import { userAxios } from './src/api/user';
import { tenantLabelAxios } from './src/api/labels';
import { dropdownAxios } from './src/api/dropdowns';
import { workPackageAxios } from './src/api/workPackage';
import { inspectionAxios, inspectionsAxios } from './src/api/inspection';
import { messageAxios } from './src/api/message';
import { commentAxios } from './src/api/comment';
import { reportAxios } from './src/api/report';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

export default function App() {
  const [fontsLoaded] = useFonts({
    Barlow_100Thin,
    Barlow_100Thin_Italic,
    Barlow_200ExtraLight,
    Barlow_200ExtraLight_Italic,
    Barlow_300Light,
    Barlow_300Light_Italic,
    Barlow_400Regular,
    Barlow_400Regular_Italic,
    Barlow_500Medium,
    Barlow_500Medium_Italic,
    Barlow_600SemiBold,
    Barlow_600SemiBold_Italic,
    Barlow_700Bold,
    Barlow_700Bold_Italic,
    Barlow_800ExtraBold,
    Barlow_800ExtraBold_Italic,
    Barlow_900Black,
    Barlow_900Black_Italic,
  });

  axiosInterceptor(authAxios, store);
  axiosInterceptor(commentAxios, store);
  axiosInterceptor(documentAxios, store);
  axiosInterceptor(locationAxios, store);
  axiosInterceptor(roleAxios, store);
  axiosInterceptor(tenantAxios, store);
  axiosInterceptor(tenantLabelAxios, store);
  axiosInterceptor(messageAxios, store);
  axiosInterceptor(toolsAxios, store);
  axiosInterceptor(userAxios, store);
  axiosInterceptor(dropdownAxios, store);
  axiosInterceptor(workPackageAxios, store);
  axiosInterceptor(inspectionAxios, store);
  axiosInterceptor(inspectionsAxios, store);
  axiosInterceptor(reportAxios, store);

  return (
    <StoreProvider store={store}>
      <GestureHandlerRootView style={{ flex: 1 }}>
        {fontsLoaded && <AppNavigator />}
      </GestureHandlerRootView>
    </StoreProvider>
  );
}
